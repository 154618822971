import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/profile')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, data) {
      return new Promise((resolve, reject) => {
        const url = '/auth/profile'
        axios
          .post(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
