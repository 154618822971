<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card>
      <h4 class="mb-1">
        {{ $t('Settings') }}
      </h4>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-tabs class="border-bottom">
            <b-tab :title="$t('Branch')">
              <b-row class="mt-2">

                <!-- Field: Logo -->
                <b-col
                  md="12"
                >
                  <!-- Media -->
                  <b-media class="mb-2">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="configData.business_logo"
                        size="90px"
                        rounded
                      />
                    </template>
                    <h4 class="mb-1">
                      {{ configData.business_name }}
                    </h4>
                    <div class="d-flex flex-wrap">
                      <b-button
                        variant="outline-primary"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @input="inputImageRenderer"
                        >
                        <feather-icon
                          icon="FolderIcon"
                        />&nbsp;
                        <span class="d-none d-sm-inline">Browse</span>
                      </b-button>
                    </div>
                  </b-media>

                </b-col>

                <!-- Field: Name -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Name')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="configData.business_name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Phone -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="phone"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Phone')"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="configData.business_phone"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Website -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="website"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Website')"
                      label-for="website"
                    >
                      <b-form-input
                        id="website"
                        v-model="configData.business_website"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Address -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="address"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Address')"
                      label-for="address"
                    >
                      <b-form-input
                        id="address"
                        v-model="configData.business_address"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Description -->
                <b-col
                  md="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="business_description"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Description')"
                      label-for="business_description"
                    >
                      <b-form-input
                        id="business_description"
                        v-model="configData.business_description"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>

            </b-tab>
            <b-tab :title="$t('Profile')">
              <b-row class="mt-2">

                <!-- Field: Name -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="employee_name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Name')"
                      label-for="employee_name"
                    >
                      <b-form-input
                        id="employee_name"
                        v-model="profileData.employee_name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                        disabled
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Phone -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="employee_phone"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Phone')"
                      label-for="employee_phone"
                    >
                      <b-form-input
                        id="employee_phone"
                        v-model="profileData.employee_phone"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                        disabled
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Email -->
                <b-col
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="user_email"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Email')"
                      label-for="user_email"
                    >
                      <b-form-input
                        id="user_email"
                        v-model="profileData.user_email"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder=""
                        disabled
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Reset Password -->
                <b-col
                  v-if="!isPasswordReset"
                  md="4"
                  class="mb-1"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    @click="resetPassword"
                  >
                    <feather-icon
                      icon="KeyIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Reset Password') }}</span>
                  </b-button>
                </b-col>

              </b-row>
            </b-tab>

          </b-tabs>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-1"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-secondary"
              pill
              @click="back"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BMedia, BAvatar,
  BTabs, BTab,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@/libs/image'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import configStoreModule from './configStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BTabs,
    BTab,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
    }
  },
  computed: {
  },
  methods: {
    back() {
      // Go back to the previous page
      this.$router.go(-1)
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'config'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, configStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()

    const blankData = {
      business_name: '',
      business_phone: '',
      business_description: '',
      business_address: '',
      business_logo: '',
      business_website: '',
    }

    const configData = ref(JSON.parse(JSON.stringify(blankData)))

    const profileData = ref({
      employee_name: '',
      employee_phone: '',
      user_email: '',
    })

    store.dispatch('config/fetch')
      .then(response => {
        const responseData = {
          business_name: response.data.data.branch.name,
          business_description: response.data.data.branch.description,
          business_phone: response.data.data.branch.phone,
          business_email: response.data.data.branch.email,
          business_address: response.data.data.branch.address,
          business_logo: response.data.data.branch.logo,
          business_website: response.data.data.branch.website,
        }
        configData.value = responseData
        profileData.value.employee_name = response.data.data.name
        profileData.value.employee_phone = response.data.data.phone
        profileData.value.user_email = response.data.data.user.email
      })
      .catch(error => {
        if (error.response.status === 404) {
          configData.value = undefined
        }
      })

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      configData.value.business_logo = base64
    })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(configData.value))
      store.dispatch('config/save', data)
        .then(() => {
          // save response data to localStorage.configData
          const configs = [
            { name: 'business_name', value: configData.value.business_name },
            { name: 'business_description', value: configData.value.business_description },
            { name: 'business_phone', value: configData.value.business_phone },
            { name: 'business_website', value: configData.value.business_website },
            { name: 'business_email', value: configData.value.business_email },
            { name: 'business_address', value: configData.value.business_address },
            { name: 'business_logo', value: configData.value.business_logo },
          ]
          localStorage.setItem('configData', JSON.stringify(configs))
        })
        .catch(() => {
          // alert('hi')
        })
    }

    const isPasswordReset = ref(false)
    const resetPassword = () => {
      useJwt.sendResetPasswordEmail({
            email: profileData.value.user_email,
          })
            .then(response => {
              isPasswordReset.value = true
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset email sent!',
                  text: `${response.data.message} Please check your email and Follow the instructions.`,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      isPasswordReset,
      resetPassword,
      profileData,
      configData,
      onSubmit,

      refFormObserver,
      getValidationState,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
